/**
 * Global
 *
 * Contains some global styling.
 *
 */
@import 'styles/fonts';
@import 'styles/typography';
@import 'styles/spacings';
@import 'styles/media-queries';

*,
*:before,
*:after {
	box-sizing: border-box;
}

// html {
// 	font-size: $font-size-base;
// 	text-rendering: optimizeLegibility;
// 	-moz-osx-font-smoothing: grayscale;
// 	-webkit-font-smoothing: antialiased;
// 	font-smoothing: antialiased;
// }

// body {
// 	font-family: $font-family-base;
// 	font-variant-numeric: lining-nums;
// 	font-feature-settings: 'lnum';
// 	margin: 0;
// 	font-size: $font-size-standard;
// 	background-color: #fbfbfb;
// }

#main-content {
	// margin: $spacing-4x 0 0 0;
	display: block;
	max-width: 144rem;
	background-color: white;
	margin: 0 auto;
	@media screen and (min-width: $device-medium-min) {
		box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
			0 0 8px 0 rgba(42, 42, 42, 0.2), 0 0 8px 0 rgba(42, 42, 42, 0.2);
	}
	@media screen and (max-width: $device-small-max) {
		margin: 0;
	}
}

.is-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 0.1rem;
	margin: -0.1rem;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 0.1rem;

	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}

/* Storybook */
.sb-show-main {
	background-color: white;
	padding: 1rem;
}

/* Navigation tabs */
.shareholder-nav-tabs {
	border-bottom: 0.1rem solid #dee2e6;
}

#ownershipTabs {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	margin-top: 0;
}

.shareholder-nav-tabs .shareholder-nav-item {
	margin-bottom: -0.1rem;

	a {
		text-decoration: none;
	}
}

.tab-pane {
	display: none;
}

.tab-pane.active {
	display: block;
}

.shareholder-nav-tabs .shareholder-nav-link.active,
.shareholder-nav-tabs .shareholder-nav-item.show .shareholder-nav-link {
	color: #495057;
	background-color: #fff;
	border-color: #dee2e6 #dee2e6 #fff;
}

.shareholder-nav-tabs .shareholder-nav-link {
	border: 0.1rem solid transparent;
	border-top: 0.4rem solid transparent !important;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.shareholder-nav-link {
	display: block;
	padding: 1.5rem 2rem;
	font-size: 1.6rem;
	color: #495057;

	&:hover {
		border: 0.1rem solid #c9c9c9;
		border-top: 0.4rem solid #c9c9c9 !important;
	}
}

.shareholder-nav-link.active {
	font-weight: 600;
	border-top: 0.4rem solid #0075BF !important;
}

/* Shareholder table */
.text-total {
	font-weight: 700;
	border-top: 0.2rem solid #c9c9c9;
}

.text-muted {
	font-style: italic;
	font-weight: 300;
	font-size: 1.6rem;
}

.shareholder-table {
	border: none;
	border-spacing: 0;
	margin-top: 4rem;
	width: 100%;

	.text-right {
		text-align: right;
	}

	.text-left {
		text-align: left;
	}

	thead {
		background-color: #01416f;
		color: white;
		padding: 1rem 0 1rem 0;
	}

	tr {
		td,
		th {
			border-bottom: 0.1rem solid #c9c9c9;
			padding: 1.2rem;
			font-size: 1.8rem;
			line-height: 2.6rem;
		}
	}
}

/* Cision SubscribePage*/

.modal-backdrop {
	opacity: 0.5;
}

/* Media queries */
@media screen and (max-width: 901px) {
	#ownershipTabs {
		display: block;
	}
}

@media screen and (max-width: 630px) {
	.shareholder-table {
		td,
		th {
			font-size: 1rem !important;
			line-height: 1rem !important;
		}
	}
}

@media screen and (max-width: 400px) {
	.shareholder-table {
		td,
		th {
			font-size: 0.8rem !important;
		}
	}
}
